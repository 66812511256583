import styled from "styled-components";
import { fontSize, color } from "../../config/theme";

type ContainerProps = { bgColor: string };
const Container = styled.section`
  background-color: ${(props: ContainerProps) => props.bgColor};
  overflow: hidden;
`;

type ContentProps = { fullWidth?: boolean; snapToEdge?: boolean };
const Content = styled.div`
  margin: auto;
  text-align: center;
  padding-top: ${(props: ContentProps) => (props.snapToEdge ? `0` : `2rem`)};
  padding-bottom: ${(props: ContentProps) => (props.snapToEdge ? `0` : `2rem`)};
  padding-left: ${(props: ContentProps) => (props.fullWidth ? `0` : `1rem`)};
  padding-right: ${(props: ContentProps) => (props.fullWidth ? `0` : `1rem`)};

  @media (min-width: 768px) {
    padding: ${(props: ContentProps) => (props.snapToEdge ? `0` : `2rem`)} 2rem;
    max-width: 960px;
  }
`;

const Title = styled.h3`
  color: ${(props: { textColor?: string | null }) =>
    props.textColor || color.dark};
  font-size: ${fontSize.title};
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 2rem;
`;

type DescriptionProps = {
  textColor?: string;
  marginTop?: number;
};

const Description = styled.p`
  color: ${(props: DescriptionProps) => props.textColor || color.dark};
  margin-top: ${(props: DescriptionProps) =>
    props.marginTop ? `${props.marginTop}px` : 0};
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  text-align: center;
  font-size: ${fontSize.copyLarge};
  line-height: 1.8rem;
`;

export { Container, Content, Title, Description };
