import React from "react";
import { SectionDescription } from "../Section/Section";
import {
  Container,
  ItemContainer,
  PortfolioImage,
  ContentContainer,
  IconContainer,
  Icon,
  Content,
  Title,
  Subtitle,
  Description,
} from "./PortfolioStyles";

type PortfolioItem = {
  client: string;
  role: string;
  description: string;
  icon: string;
  alt: string;
  image: string;
};

type PortfolioProps = {
  items: PortfolioItem[];
};

export const PortfolioDescription = () => (
  <SectionDescription>
    Below are some examples of work I have done. Please get in touch if you are
    interested in working together to produce a native mobile app or website.
  </SectionDescription>
);

function Portfolio(props: PortfolioProps) {
  return (
    <Container>
      {props.items.map((work, index) => (
        <ItemContainer key={`portfolio:${index}`}>
          <PortfolioImage
            src={work.image}
            alt={`Portfolio imagery showcasing the ${work.alt}`}
          />
          <ContentContainer>
            <IconContainer>
              <Icon src={work.icon} alt={work.alt} />
            </IconContainer>
            <Content>
              <Title>{work.client}</Title>
              <Subtitle>{work.role}</Subtitle>
              <Description>{work.description}</Description>
            </Content>
          </ContentContainer>
        </ItemContainer>
      ))}
    </Container>
  );
}

export default Portfolio;
