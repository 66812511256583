// Ref: https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Improving_scrolling_performance_with_passive_listeners
const hasPassiveListeners = () => {
  let supportsPassive = false;

  try {
    // @ts-ignore
    window.addEventListener(
      "test",
      null,
      Object.defineProperty({}, "passive", {
        get: function () {
          supportsPassive = true;
        },
      })
    );
  } catch (err) {}

  return supportsPassive;
};

export { hasPassiveListeners };
