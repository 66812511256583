import styled from "styled-components";
import { fontSize } from "../../config/theme";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
`;

const Content = styled.div`
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  align-items: center;

  @media (max-width: 767px) {
    margin-bottom: 2rem;
    width: 100%;

    :last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    flex: 1;

    max-width: 33.3333333333%;
  }
`;

const Icon = styled.img`
  width: 80px;
  height: 80px;
`;

const Title = styled.h4`
  font-weight: 700;
  line-height: 1.2;
  font-size: ${fontSize.copyLarge};

  @media (max-width: 767px) {
    margin-bottom: 0.8rem;
  }
`;

const Description = styled.p`
  font-size: ${fontSize.copyRegular};
  line-height: 1.6;

  @media (max-width: 767px) {
    margin: auto;
    max-width: 600px;
  }
`;

export { Container, Content, Icon, Title, Description };
