import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #1d1d1f;
  }

  p {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  p > b {
    font-weight: 900;
  }

  input, label, textarea, button {
    font-family: "Raleway";
  }

  .grecaptcha-badge { visibility: hidden; }
`;

export default GlobalStyle;
