import React from "react";
import styled from "styled-components";
import { fontSize, color } from "../../config/theme";

const Container = styled.div`
  padding: 1rem;
  padding-bottom: 4rem;
  color: ${color.light};
`;

const Text = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${fontSize.copySmall};
`;

function Footer() {
  return (
    <Container>
      <Text>
        Built using ReactJS via Create React App. Icons supplied by FontAwesome.
        Photography by me, Sukh.
      </Text>
      <Text>Copyright © Interface Engineer Limited. All rights reserved.</Text>
    </Container>
  );
}

export default Footer;
