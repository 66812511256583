import styled from "styled-components";

const Logo = styled.img`
  width: 100%;
  max-width: 100px;
  margin: 0 auto;
  pointer-events: none;
`;

export default Logo;
