import styled from "styled-components";
import { color, fontSize } from "../../config/theme";

const Button = styled.button`
  border: 0;
  background-color: ${color.primary};
  padding: 1rem 2rem;
  border-radius: 6px;
  color: ${color.white};
  font-size: ${fontSize.copyRegular};
  align-self: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  text-transform: uppercase;

  &:hover {
    background-color: ${color.white};
    color: ${color.primary};
  }
`;

export default Button;
