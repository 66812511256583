import React from "react";
import styled from "styled-components";
import { fontSize } from "../../config/theme";

type HeaderProps = {
  imageSrc: string;
};
const Header = styled.header`
  background-image: url(${(props: HeaderProps) => props.imageSrc});
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: bottom;
  background-size: cover;
  padding: 10rem 2rem 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: white;
  text-align: center;

  @media (min-width: 768px) {
    background-attachment: fixed;
    justify-content: center;
    padding: 0;
    height: 100vh;
    width: 100vw;
  }
`;

const Headline = styled.h1`
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.7);
  font-weight: 700;
  font-size: ${fontSize.subheadline};
`;

const Subheadline = styled.h2`
  margin-top: 0;
  text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.7);
  font-weight: 300;
  font-size: ${fontSize.headline};
`;

type BackgroundSectionProps = {
  backgroundImage: string;
  headline?: string;
  subheadline?: string;
  children?: React.ReactNode;
};

function BackgroundSection(props: BackgroundSectionProps) {
  return (
    <Header imageSrc={props.backgroundImage}>
      <Headline>
        {"Welcome to "}
        <br /> {"Interface Engineer"}
      </Headline>
      {props.subheadline && <Subheadline>{props.subheadline}</Subheadline>}
      {props.children}
    </Header>
  );
}

export default BackgroundSection;
