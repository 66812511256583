import styled from "styled-components";
import { fontSize, color } from "../../config/theme";

const Container = styled.div`
  position: relative;

  @media (min-width: 768px) {
    margin: 0 2rem;
  }

  @media (min-width: 1280px) {
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const ItemContainer = styled.div`
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;

    :nth-child(even) {
      flex-direction: row-reverse;

      > div {
        text-align: right;

        @media (min-width: 1024px) {
          > span {
            right: 8px;
            left: initial;
          }

          > div {
            padding-left: 0;
            padding-right: 60px;
          }
        }
      }
    }
  }
`;

const PortfolioImage = styled.img`
  width: 100%;
  max-width: 768px;

  @media (min-width: 768px) {
    align-self: center;
    width: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 20px;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  margin: 1rem auto 0;
  max-width: 600px;
  padding: 0 1rem;

  :first-child {
    margin-top: 0;
  }

  @media (min-width: 768px) {
    text-align: left;
  }
`;

const IconContainer = styled.span`
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  @media (max-width: 767px) {
    left: calc(50% - 20px);
  }
  @media (min-width: 768px) {
    display: none;
  }

  @media (min-width: 1024px) {
    width: 60px;
    height: 60px;
    left: 8px;
    display: initial;
  }
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;

  @media (min-width: 768px) {
    width: 60px;
    height: 60px;
  }
`;

const Content = styled.div`
  position: relative;
  color: ${color.dark};

  @media (max-width: 767px) {
    padding-top: 3rem;
  }

  @media (min-width: 1024px) {
    padding-left: 60px;
  }
`;

const Title = styled.h3`
  font-size: ${fontSize.copyLarge};
  margin: 0;
`;

const Subtitle = styled.h4`
  font-size: ${fontSize.title};
  margin: 0;
`;

const Description = styled.p`
  margin: 1em 0 0;
  font-size: ${fontSize.copyRegular};
  line-height: 1.6;
`;

export {
  Container,
  ItemContainer,
  PortfolioImage,
  ContentContainer,
  IconContainer,
  Icon,
  Content,
  Title,
  Subtitle,
  Description,
};
