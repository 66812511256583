import React from "react";
import { Container, Content, Icon, Title, Description } from "./ServiceStyles";
import Circle, { DefaultCircleProps } from "../common/Circle";
import { color } from "../../config/theme";

type ServiceItem = {
  icon: string;
  title: string;
  description: string;
};

type ServiceProps = {
  items: ServiceItem[];
};

function Service(props: ServiceProps) {
  return (
    <Container>
      {props.items.map((serviceItem, index) => (
        <Content key={`Service:${index}`}>
          <Circle {...DefaultCircleProps} backgroundColor={color.primary}>
            <Icon src={serviceItem.icon} alt={`${serviceItem.title} icon`} />
          </Circle>
          <Title>{serviceItem.title}</Title>
          <Description
            dangerouslySetInnerHTML={{ __html: serviceItem.description }}
          />
        </Content>
      ))}
    </Container>
  );
}

export default Service;
