import iconMobile from "../assets/svgs/mobile-alt.svg";
import iconDesktop from "../assets/svgs/desktop.svg";
import iconServer from "../assets/svgs/server.svg";
// import brandEurostar from "../assets/brands/brandEurostar.jpg";
// import brandFreeFrom from "../assets/brands/brandFreeFromFestival.jpg";
// import brandLibratone from "../assets/brands/brandLibratone.jpg";
// import brandAsda from "../assets/brands/brandAsda.jpg";
import brandHalifax from "../assets/brands/brandHalifax.jpg";
// import brandNIHR from "../assets/brands/brandNIHR.jpg";
import brandFatface from "../assets/brands/brandFatface.jpg";
import brandInvestec from "../assets/brands/brandInvestec.jpg";
import brandBritishLibrary from "../assets/brands/brandBritishLibrary.jpg";
import brandAnchor from "../assets/brands/brandAnchor.jpg";
import brandTheHouseOfPeroni from "../assets/brands/brandTheHouseOfPeroni.jpg";
import brandTictrac from "../assets/brands/brandTictrac.jpg";
import brandJLR from "../assets/brands/brandJLR.jpg";
import brandFirstgroup from "../assets/brands/brandFirstgroup.jpg";
import brandTescoMobile from "../assets/brands/brandTescoMobile.jpg";
import workEconomistIcon from "../assets/work/icon-economist.jpg";
import workEconomistHero from "../assets/work/cover-image-economist.jpg";
import workEurostarIcon from "../assets/work/icon-eurostar.png";
import workEurostarHero from "../assets/work/cover-image-eurostar.jpg";
import workFreeFromIcon from "../assets/work/free_from_app.png";
import workFreeFromHero from "../assets/work/cover-image-freefrom.jpg";
import workNIHRIcon from "../assets/work/icon-nihr.jpg";
import workNIHRHero from "../assets/work/cover-image-nihr-2.jpg";
import workLibratoneIcon from "../assets/work/icon-libratone.png";
import workLibratoneHero from "../assets/work/cover-image-libratone.jpg";
import workAsdaIcon from "../assets/work/icon-asda.png";
import workAsdaHero from "../assets/work/cover-image-asda.jpg";
import linkGithub from "../assets/svgs/github.svg";
import linkLinkedin from "../assets/svgs/linkedin.svg";
import linkYunojuno from "../assets/svgs/yunojuno.svg";

export default {
  services: [
    {
      icon: iconMobile,
      title: "Mobile apps",
      description:
        "As a <b>React Native ninja</b> I can build cross platform apps for iOS & Android. I also have experience building pure native iOS apps using Swift and Apple's new UI framework, SwiftUI and have knowledge of old school languages including Objective-C. ",
    },
    {
      icon: iconDesktop,
      title: "Responsive websites",
      description:
        "I help build responsive, accessible and efficient websites using HTML5 & CSS3 (inc. SCSS). I have worked across a variety of JavaScript codebases including jQuery, Angular and am currently a <b>React JS developer</b>. I have also worked on pure native ES6+ codebases.",
    },
    {
      icon: iconServer,
      title: "Backend services",
      description:
        "Specialising in <b>NodeJS</b> I can help build micro services inc. RESTful APIs using GraphQL. I have built Backend for Frontend (BFF) architecture APIs and am also skilled in using platforms such as Firebase. I can also set up Continuous Integration & Deployment pipelines.",
    },
  ],
  brands: [
    { image: brandFirstgroup, alt: "Firstgroup" },
    { image: brandTescoMobile, alt: "Tesco Mobile" },
    // { image: brandFreeFrom, alt: "Free From Festival" },
    { image: brandTheHouseOfPeroni, alt: "The House Of Peroni" },
    // { image: brandLibratone, alt: "Libratone" },
    // { image: brandAsda, alt: "Asda" },
    { image: brandHalifax, alt: "Halifax" },
    // { image: brandNIHR, alt: "NIHR" },
    { image: brandJLR, alt: "Jaguar Land Rover" },
    { image: brandBritishLibrary, alt: "British Library" },
    { image: brandInvestec, alt: "Investec" },
    // { image: brandEurostar, alt: "Eurostar" },
    { image: brandAnchor, alt: "Anchor" },
    { image: brandFatface, alt: "Fatface" },
    { image: brandTictrac, alt: "Tictrac" },
  ],
  work: [
    {
      client: "The Economist",
      role: "Senior Mobile Engineer",
      description:
        "Came into the Mobile team at the start of the complete rebuild & transplant of The Economist's core app (from v2 to v3.x) for both iOS & Android. Postlaunch features inc. new grid layouts, full responsive tablet support, content caching strategies, in-app subscriptions & improving audio experience inc. streaming & file downloading mechanisms etc.",
      icon: workEconomistIcon,
      image: workEconomistHero,
      alt: "The Economist iOS and Android app",
    },
    {
      client: "Eurostar",
      role: "Mobile Tech Lead",
      description:
        "Led agile team on greenfield project to rebuild the Eurostar app for iOS and Android using at the time, recently launched React Native framework. Initial prototype scoped out on feasibility of React Native. MVP launched within 4 months of development in December 2017. App Store ratings shot up after launch.",
      icon: workEurostarIcon,
      image: workEurostarHero,
      alt: "Eurostar iOS and Android app",
    },
    {
      client: "Free From Festival",
      role: "iOS Developer",
      description:
        "Sole developer for the iOS app for Free From Events. Within 2 weeks of discussions we went from requirement gathering to full release on the iOS AppStore. Built using React Native, Firebase & Realm with offline first approach. Continuous support & updates for each event throughout the year.",
      icon: workFreeFromIcon,
      image: workFreeFromHero,
      alt: "Free From Festival iOS app",
    },
    {
      client: "NIHR",
      role: "iOS Developer",
      description:
        "Greenfield project working as the sole iOS developer to build an iOS prototype app for the National Institute for Health Research. Core principle of the app was to investigate Apple's new HealthKit & ResearchKit frameworks. The app was handed over and launched internally as an enterprise app.",
      icon: workNIHRIcon,
      image: workNIHRHero,
      alt: "NIHR Enterprise iOS app",
    },
    {
      client: "Libratone",
      role: "Senior Front End Developer",
      description:
        "Responsive front end rebuild of the core website and checkout flow screens with full support on iOS, Android and desktop browsers. Primary goal was to improve conversion rates and performance. Developed using vanilla ES6 Javascript , HTML5 and CSS3 animations.",
      icon: workLibratoneIcon,
      image: workLibratoneHero,
      alt: "Libratone website",
    },
    {
      client: "Asda",
      role: "Lead Front End Developer",
      description:
        "Responsive front end build of World Book Day campaign Asda were launching. Website built using React and includes an online quiz with randomised questions to determine the customers character. Based on the character identified a selection of outfits were presented to buy.",
      icon: workAsdaIcon,
      image: workAsdaHero,
      alt: "Asda World Book Day campaign",
    },
  ],
  links: [
    {
      uri: "https://www.linkedin.com/in/thesukh",
      image: linkLinkedin,
      text: "LinkedIn",
    },
    {
      uri: "https://uk.yunojuno.com/p/sukh-kalsi",
      image: linkYunojuno,
      text: "YunoJuno",
    },
    {
      uri: "https://github.com/sukhkalsi",
      image: linkGithub,
      text: "GitHub",
    },
  ],
};
