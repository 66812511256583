import React, { useState, Fragment } from "react";
import styled from "styled-components";
import Logo from "./Logo";
import companyLogo from "../../assets/generic/interface_engineer_logo_2022.jpg";
import { color } from "../../config/theme";
import { SectionRefObject } from "../Section/Section";
import { sendAnalytics } from "../../utils/analytics";

export enum HamburgerVariant {
  Light,
  Dark,
}

type NavContainerProps = { isShowing: boolean };
const NavContainer = styled.nav`
  position: fixed;
  /* width: 100%;
  height: 100%; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${color.white};
  z-index: 10;
  transform: ${(props: NavContainerProps) =>
    `translateX(${props.isShowing ? 0 : "-100%"})`};
  transition: transform 0.3s ease-in-out;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  padding: 80px 20px 0;

  @media (min-width: 768px) {
    padding: 40px;
    max-width: 400px;
  }
`;

const NavLinks = styled.ul`
  list-style-type: none;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  margin-top: 40px;
`;

const NavLink = styled.li``;

const Link = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: ${color.primary};
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 300;

  @media (min-width: 768px) {
    /* font-size: 70px; */
    font-weight: 400;
  }
`;

type BurgerProps = { open: boolean; hamburgerVariant: HamburgerVariant };
const BurgerMenu = styled.button`
  position: fixed;
  top: 2rem;
  left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 20;

  @media (max-width: 767px) {
    position: ${({ open }: BurgerProps) => (open ? "fixed" : "absolute")};
  }

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open, hamburgerVariant }: BurgerProps) =>
      open || hamburgerVariant === HamburgerVariant.Dark
        ? color.dark
        : color.light};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }: BurgerProps) =>
        open ? "rotate(45deg)" : "rotate(0)"};
    }

    :nth-child(2) {
      opacity: ${({ open }: BurgerProps) => (open ? "0" : "1")};
      transform: ${({ open }: BurgerProps) =>
        open ? "translateX(20px)" : "translateX(0)"};
    }

    :nth-child(3) {
      transform: ${({ open }: BurgerProps) =>
        open ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
`;

type NavItem = {
  title?: string;
  ref?: React.RefObject<SectionRefObject>;
};

type NavProps = {
  hamburgerVariant: HamburgerVariant;
  items: Array<NavItem>;
};

function Nav(props: NavProps) {
  const [showNav, setShowNav] = useState(false);

  const onAppLinkPress = (
    e: React.MouseEvent<HTMLButtonElement>,
    ref?: React.RefObject<SectionRefObject>,
    title?: string
  ) => {
    e.preventDefault();

    setShowNav(false);

    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    sendAnalytics("nav_link", { link_item: title || "unknown_nav_title" });
  };

  return (
    <Fragment>
      <BurgerMenu
        aria-label={`Tap this element to ${
          !showNav ? "open" : "close"
        } the navigation menu`}
        hamburgerVariant={props.hamburgerVariant}
        open={showNav}
        onClick={() => {
          const navAction = !showNav;
          setShowNav(navAction);
          sendAnalytics("nav_menu", { action: navAction ? "open" : "close" });
        }}
      >
        <div />
        <div />
        <div />
      </BurgerMenu>
      <NavContainer isShowing={showNav}>
        <Logo src={companyLogo} alt="Interface Engineer logo" />
        <NavLinks>
          {props.items.map((item, index) => (
            <NavLink key={`navLink:${index}`}>
              <Link
                aria-label={`Tap to go to the ${item.title} section`}
                onClick={(e) => onAppLinkPress(e, item.ref, item.title)}
              >
                {item.title}
              </Link>
            </NavLink>
          ))}
        </NavLinks>
      </NavContainer>
    </Fragment>
  );
}

export default Nav;
