import React, { Fragment, useEffect, useState, useRef } from "react";
import { throttle } from "lodash";
import GlobalStyle from "./components/Global";
import homepageBackground from "./assets/generic/graffiti_bg.jpg";
import data from "./config/data";
import { Profile, AboutDescription } from "./components/About";
import Brands, { BrandsDescription } from "./components/Brands/Brands";
import BackgroundSection from "./components/common/BackgroundSection";
import Nav, { HamburgerVariant } from "./components/common/Nav";
import Portfolio, {
  PortfolioDescription,
} from "./components/Portfolio/Portfolio";
import Section, {
  SectionDescription,
  SectionProps,
  SectionVariant,
} from "./components/Section/Section";
import Service from "./components/Service/Service";
import Banner from "./components/Banner";
import Button from "./components/common/Button";
// import Contact from "./components/Contact/Contact";
import Footer from "./components/common/Footer";
import Links from "./components/Links";
import Cookies from "./components/Cookies";
import { sendAnalytics } from "./utils/analytics";
import { hasPassiveListeners } from "./utils/window";
import { color } from "./config/theme";

const getWindowPosition = () => ({
  x: window.pageXOffset,
  y: window.pageYOffset,
  height: window.innerHeight,
});

function App() {
  const [hamburgerType, setHamburgerType] = useState<HamburgerVariant>(
    HamburgerVariant.Light
  );
  const hamburgerHeight = 50;

  const getInTouchTitle = "Get in touch";
  const sections: Array<SectionProps> = [
    {
      variant: SectionVariant.Light,
      title: "About",
      postTitleContent: <Profile />,
      description: <AboutDescription />,
      children: <Service items={data.services} />,
      ref: useRef(null),
    },
    {
      variant: SectionVariant.Secondary,
      children: <Banner onPress={() => onHireMePress()} />,
    },
    {
      variant: SectionVariant.Light,
      fullWidth: true,
      title: "Work",
      description: <PortfolioDescription />,
      children: <Portfolio items={data.work} />,
      ref: useRef(null),
    },
    {
      variant: SectionVariant.Default,
      title: "Brands",
      description: <BrandsDescription />,
      children: <Brands items={data.brands} />,
      ref: useRef(null),
    },
    // {
    //   variant: SectionVariant.Primary,
    //   title: "Get in touch",
    //   children: <Contact />,
    //   ref: useRef(null),
    // },
    {
      variant: SectionVariant.PrimaryDark,
      title: getInTouchTitle,
      description: (
        <SectionDescription textColor={color.light}>
          If you want to work with me you can hire me on YunoJuno. Alternatively
          if you wish to discuss a project / start up idea feel free to contact
          me via LinkedIn and I'll get back to you as soon as possible{" "}
          <span role="img" aria-label="geek">
            🤓
          </span>
        </SectionDescription>
      ),
      children: <Links items={data.links} />,
      ref: useRef(null),
    },
    {
      variant: SectionVariant.Dark,
      children: <Footer />,
      ref: useRef(null),
      snapToEdge: true,
    },
  ];

  const contactSection = sections.find(
    (section) => section.title === getInTouchTitle
  );
  const onHireMePress = () => {
    contactSection?.ref?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const supportsPassiveListeners = hasPassiveListeners();
  useEffect(() => {
    const onScroll = throttle(() => {
      const { y, height } = getWindowPosition();
      const isTabletOrBigger = window.innerWidth >= 768;
      const isCurrentScrollBeyondFold = y + hamburgerHeight >= height;

      /* Hamburger process */
      if (
        isTabletOrBigger &&
        isCurrentScrollBeyondFold &&
        hamburgerType === HamburgerVariant.Light
      ) {
        setHamburgerType(HamburgerVariant.Dark);
      }

      if (
        isTabletOrBigger &&
        !isCurrentScrollBeyondFold &&
        hamburgerType === HamburgerVariant.Dark
      ) {
        setHamburgerType(HamburgerVariant.Light);
      }
    }, 250);

    window.addEventListener(
      "scroll",
      onScroll,
      supportsPassiveListeners ? { passive: true } : false
    );

    return () => {
      onScroll.cancel();
      window.removeEventListener("scroll", onScroll);
    };
  });

  const navItems = sections
    .filter((section) => section.ref)
    .map((section) => ({
      title: section.title,
      ref: section.ref,
    }));

  return (
    <Fragment>
      <GlobalStyle />

      <Nav items={navItems} hamburgerVariant={hamburgerType} />

      <BackgroundSection
        backgroundImage={homepageBackground}
        subheadline={"Mobile & web specialist"}
      >
        <Button
          role="button"
          onClick={() => {
            sections[0].ref?.current?.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
            sendAnalytics("cta", { cta_type: "hero" });
          }}
        >
          Find out more
        </Button>
      </BackgroundSection>

      {sections.map((section, index) => {
        const { children, ...sectionProps } = section;
        const key = `section:${index}`;

        if (children) {
          return (
            <Section key={key} {...sectionProps}>
              {children}
            </Section>
          );
        }

        return <Section key={key} {...sectionProps} />;
      })}
      <Cookies />
    </Fragment>
  );
}

export default App;
