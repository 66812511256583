import styled from "styled-components";

export const BrandsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
  justify-content: space-around;
`;

export const BrandItem = styled.div`
  max-width: 401px;
  max-height: 101px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    width: 33.3333333333%;
    flex-grow: 0;
    flex-shrink: 0;
  }

  @media (min-width: 1024px) {
    width: 25%;
  }
`;

export const BrandImage = styled.img`
  max-height: 60px;
`;
