import React from "react";
import Circle, { DefaultCircleProps } from "./common/Circle";
import styled from "styled-components";
import avatar from "../assets/generic/avatar.jpg";
import { SectionDescription } from "./Section/Section";

const ProfileImage = styled.img`
  height: 200px;
  width: 200px;
`;

export const Profile = () => (
  <Circle {...DefaultCircleProps} size={200}>
    <ProfileImage
      src={avatar}
      alt="A person named Sukh standing in the grand stand area of Camp Nou football stadium in Barcelona"
    />
  </Circle>
);

export const AboutDescription = () => (
  <SectionDescription marginTop={10}>
    My name is Sukh and I am Interface Engineer. <br />
    Based in London and also available as a remote dev, I am a contractor who
    specialises in making mobile apps and websites. <br />I have been a
    professional developer since 2010. I have worked across a variety of
    projects for a huge range of clients ranging from start-ups to large
    enterprises. I have worked as a sole developer, senior developer and as a
    technical lead across various agile teams. See below the services and skills
    I can offer:
  </SectionDescription>
);
