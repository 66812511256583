// import React from "react";
import styled from "styled-components";

type CircleProps = {
  size: number;
  backgroundColor: string;
};

const Circle = styled.div`
  height: ${(props: CircleProps) => `${props.size}px`};
  width: ${(props: CircleProps) => `${props.size}px`};
  border-radius: ${(props: CircleProps) => `${props.size / 2}px`};
  background: ${(props: CircleProps) => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  overflow: hidden;
`;

// TODO: get styled components to behave with React default props!
// Circle.defaultProps = {
//   size: 120,
//   backgroundColor: "transparent",
// };

// Temp workaround for the above issue
export const DefaultCircleProps: CircleProps = {
  size: 120,
  backgroundColor: "transparent",
};

export default Circle;
