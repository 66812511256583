import React from "react";
import { BrandsContainer, BrandItem, BrandImage } from "./BrandStyles";
import { SectionDescription } from "../Section/Section";

type BrandItem = {
  image: string;
  alt: string;
};
type BrandsProps = {
  items: Array<BrandItem>;
};

export const BrandsDescription = () => (
  <SectionDescription>
    In addition I have been fortunate to work with a range of awesome brands
    during my career as a developer. Below is a small collection:
  </SectionDescription>
);

function Brands(props: BrandsProps) {
  return (
    <BrandsContainer>
      {props.items.map((brand, index) => (
        <BrandItem key={`brand:${index}`}>
          <BrandImage src={brand.image} alt={brand.alt} />
        </BrandItem>
      ))}
    </BrandsContainer>
  );
}

export default Brands;
