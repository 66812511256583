export const color = {
  white: "#ffffff",
  light: "#f8f9fa",
  dark: "#1d1d1f",
  primary: "#006BB3",
  primaryDark: "#335994",
  secondary: "#0ABFA7",
  errors: {
    primary: "#e30000",
    primaryLight: "#fff2f4",
  },
};

export const fontSize = {
  copySmall: "0.8125rem",
  copyRegular: "1rem",
  copyLarge: "1.1rem",
  title: "1.5rem",
  subheadline: "1.8rem",
  headline: "2rem",
};
