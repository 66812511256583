import React from "react";
import styled from "styled-components";
import { color, fontSize } from "../config/theme";
import Button from "./common/Button";
import { sendAnalytics } from "../utils/analytics";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const Heading = styled.h2`
  color: ${color.white};
  margin-left: auto;
  margin-right: auto;
  font-size: ${fontSize.headline};
`;

type Props = { onPress: Function };
const Banner = (props: Props) => (
  <Container>
    <Heading>Do you want to work with me?</Heading>
    <Button
      role="button"
      onClick={() => {
        props.onPress();
        sendAnalytics("cta", { cta_type: "banner" });
      }}
    >
      Get in touch
    </Button>
  </Container>
);

export default Banner;
