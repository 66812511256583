import React from "react";
import styled from "styled-components";
import { color } from "../config/theme";
import { sendAnalytics } from "../utils/analytics";

const OnlineLinks = styled.div`
  display: flex;
  justify-content: space-around;
`;

const OnlineLink = styled.a`
  background-color: transparent;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: ${color.primary};
  }

  p {
    text-decoration: none;
    color: ${color.white};
    margin: 0;
  }
`;

const OnlinePlatform = styled.img`
  width: 40px;
  height: 40px;
`;

type LinkItem = {
  uri: string;
  image: string;
  text: string;
};

type LinkProps = { items: Array<LinkItem> };

const onLinkPress = (item: LinkItem) => {
  window.open(item.uri, "_blank");
  sendAnalytics("online_link", { link_item: item.text });
};

const Links = (props: LinkProps) => (
  <OnlineLinks>
    {props.items.map((linkItem, index) => (
      <OnlineLink
        key={`onlineLink:${index}`}
        onClick={() => onLinkPress(linkItem)}
      >
        <OnlinePlatform
          src={linkItem.image}
          alt={`Icon image of ${linkItem.text}`}
        />
        <p>{linkItem.text}</p>
      </OnlineLink>
    ))}
  </OnlineLinks>
);

export default Links;
