import React, { useState } from "react";
import styled from "styled-components";
import { color, fontSize } from "../config/theme";
import Button from "./common/Button";
import { sendAnalytics } from "../utils/analytics";

const Container = styled.div`
  background-color: ${color.dark};
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-content: space-between;
  flex-direction: column;
  padding: 0 2rem 0.25rem 1rem;

  @media (min-width: 768px) {
    padding: 0 1rem;
    flex-direction: row;
  }
`;

const Text = styled.p`
  color: ${color.white};
  font-size: ${fontSize.copySmall};
`;

const Accept = styled(Button)`
  background-color: ${color.secondary};
  padding: 0.5rem 1rem;
  font-size: ${fontSize.copySmall};
  margin-left: 1rem;
`;

const Close = styled.a`
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -10px;
  width: 15px;
  height: 15px;
  padding: 10px;
  outline: 0;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    content: " ";
    height: 15px;
    width: 2px;
    top: 3px;
    background-color: #fff;
  }

  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }

  @media (min-width: 768px) {
    right: 15px;
  }
`;

const COOKIE_KEY = "cookie_notice_accepted";

function hasCookieSet(): boolean {
  if (
    document.cookie
      .split(";")
      .some((item) => item.trim().startsWith(COOKIE_KEY))
  ) {
    return true;
  }

  return false;
}

function setCookie(): void {
  document.cookie = `${COOKIE_KEY}=true;max-age=31536000;`;
}

function Cookies() {
  const [hasAgreed, setHasAgreed] = useState(hasCookieSet());

  const onCookieAccept = (clickType: string) => {
    setCookie();
    if (hasCookieSet()) {
      setHasAgreed(true);
    }

    sendAnalytics("cookie", { type: clickType });
  };

  if (hasAgreed) {
    return null;
  }

  return (
    <Container>
      <Content>
        <Text>
          Cookies! Yes, they're very tasty &amp; just like most websites this
          site uses them. If you continue to use my site I'll assume you're OK
          with it.
        </Text>
        <Accept onClick={() => onCookieAccept("accept_btn")}>Sure!</Accept>
      </Content>
      <Close onClick={() => onCookieAccept("dismiss")} />
    </Container>
  );
}

export default Cookies;
