import * as firebase from "firebase/app";
import "firebase/analytics";
import FIREBASE_CONFIG from "../config/firebase";

const initialiseFirebase = () => {
  firebase.initializeApp({
    ...FIREBASE_CONFIG,
  });

  firebase.analytics();
};

const sendAnalytics = (eventName: string, eventProperties = {}) => {
  firebase.analytics().logEvent(eventName, eventProperties);
};

export { initialiseFirebase, sendAnalytics };
