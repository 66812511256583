import React, { ReactNode } from "react";
import { color } from "../../config/theme";
import { Container, Content, Title, Description } from "./SectionStyles";

export const SectionDescription = Description;

export enum SectionVariant {
  Default,
  Light,
  Dark,
  Primary,
  PrimaryDark,
  Secondary,
}

export type SectionRefObject = HTMLDivElement;

function getBackground(variant: SectionVariant): string {
  switch (variant) {
    case SectionVariant.Light:
      return color.light;

    case SectionVariant.Dark:
      return color.dark;

    case SectionVariant.Primary:
      return color.primary;

    case SectionVariant.PrimaryDark:
      return color.primaryDark;

    case SectionVariant.Secondary:
      return color.secondary;

    default:
      return color.white;
  }
}

export type SectionProps = {
  fullWidth?: boolean;
  variant: SectionVariant;
  title?: string;
  // navText?: string /* Use to override the menu nav text. Default will use title */;
  postTitleContent?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  snapToEdge?: boolean;
  ref?: React.RefObject<SectionRefObject>;
};

// function Section(props: SectionProps) {
const Section: React.ComponentType<SectionProps> = React.forwardRef(
  (props: SectionProps, ref?: React.Ref<SectionRefObject>) => {
    return (
      <Container ref={ref} bgColor={getBackground(props.variant)}>
        <Content fullWidth={props.fullWidth} snapToEdge={props.snapToEdge}>
          {props.title && (
            <Title
              textColor={
                props.variant === SectionVariant.Dark ||
                props.variant === SectionVariant.Primary ||
                props.variant === SectionVariant.PrimaryDark ||
                props.variant === SectionVariant.Secondary
                  ? color.light
                  : null
              }
            >
              {props.title}
            </Title>
          )}

          {props.postTitleContent && props.postTitleContent}

          {props.description && props.description}

          {props.children && props.children}
        </Content>
      </Container>
    );
  }
);

export default Section;
